@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .sidebar-icon {
        @apply relative flex items-center justify-center h-11 w-11 m-2 mx-auto bg-gray-700
        text-white hover:bg-lime-600 rounded-3xl hover:rounded-xl
        transition-all duration-200 ease-linear cursor-pointer;
    }

    .sidebar-tooltip {
        @apply absolute w-auto ml-4 px-2 py-0.5 m-2 z-20 min-w-max left-12 rounded-md text-white bg-gray-900 text-base transition-all scale-0 ease-linear origin-left group-hover:scale-100;
    }

    .sidebar-logo {
        @apply relative flex  transition-all duration-300 items-center justify-center h-12 w-12 hover:text-white rounded-2xl cursor-pointer
    }

    .sidebar-arrow {
        @apply bg-gray-900 ring-2 ring-white text-white z-20 rounded-full absolute -right-2 top-6 cursor-pointer
    }

    .sidebar-hr {
        @apply bg-gray-700 my-2 w-full border border-gray-700 rounded-full;
    }

    .top-header {
        @apply sticky top-0 z-10 flex w-full bg-white drop-shadow-md;
    }

    .custom-select {
        @apply block w-full p-2 pl-2 text-xs text-black border appearance-none border-gray-300 rounded-md focus:outline-none hover:cursor-pointer;
    }

    .custom-checkbox {
        @apply rounded-md focus:outline-none hover:cursor-pointer;
    }

    .custom-input {
        @apply block w-full p-2 pl-2 text-xs text-black border appearance-none border-gray-300 rounded-md focus:outline-none
    }

    .block-btn {
        @apply text-white flex items-center justify-center gap-1 bg-gray-900 duration-300 hover:bg-gray-600 rounded-lg text-sm px-4 py-1;
    }

    .delete-btn {
        @apply text-white flex items-center justify-center gap-1 bg-red-600 duration-300 hover:bg-red-500 rounded-lg text-sm px-4 py-1;
    }

    .edit-btn {
        @apply text-white flex items-center justify-center gap-1 bg-sky-600 duration-300 hover:bg-sky-500 rounded-lg text-sm px-4 py-1;
    }

    .unblock-btn {
        @apply text-black flex items-center justify-center gap-1 bg-white duration-300 hover:bg-gray-900 hover:text-white hover:border-0 rounded-lg text-sm px-4 py-1;
    }

    .submit-btn {
        @apply text-white flex items-center  justify-center gap-1 bg-lime-600 duration-300 hover:bg-gray-900 rounded-lg text-sm px-4 py-1;
    }

    .clear-btn {
        @apply text-white flex items-center justify-center gap-1 bg-gray-900 duration-300 hover:bg-red-600 rounded-lg text-sm px-3 py-1 cursor-pointer;
    }
}